<template>
    <div class="page">
        <p>1、账号授权后即可发单，实付运费与授权账号内实付运费相同；商家自有运力的运费直接从商家授权账号扣减。</p>
        <p>2、商家使用自有运力发配送单，猪耳朵平台从商家在猪耳朵的预付款账号收取相应的服务费， 具体收费金额如下展示：</p>
        <table v-if="range.length">
            <tr>
                <th>完成配送单数</th><th>收费(元)</th>
            </tr>
            <tr v-for="(item, index) in range" :key="index">
                <td>{{item.startOrderNum}} - {{item.endOrderNum == -1 ? '不限' : item.endOrderNum}}</td><td>{{item.serviceFee}}</td>
            </tr>
        </table>
        <p>3、充值金额：不限，请根据您的订单量预估充值，建议充值金额充足些，避免由于余额不足导致发单失败。</p>
    </div>
</template>
<script>
import http from '@/http';

export default {
    data(){
        return {
            range: []
        };
    },
    created() {
        http.post('/?cmd=com.xqxc.api.pigear.biz.platform.TransportCompanyService.getOwnedTransportServiceFeeRange', {
            shopId: this.$route.query.shopId || 0
        }).then(res => {
            this.range = res.result || [];
        })
    },
    mounted() {
    },
    methods: {
        
    },
}
</script>
<style lang="less" scoped>
.page { 
    padding: .3rem;
    font-size: .28rem;
    line-height: 1.5;
    p{
        padding: .2rem 0;
    }
    table{
        width: 100%;
        border: 1px solid #ddd;
        margin: .1rem auto;
        th, td{
            width: 50%;
            height: .6rem;
            line-height: .6rem;
            text-align: center;
            border: 1px solid #ddd;
        }
        th{
            background: #f2f2f2;
        }
    }
}
    
</style>
    